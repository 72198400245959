// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$primary: mat-palette($mat-indigo);
$accent: mat-palette($mat-pink, A200, A100, A400);
// The warn palette is optional (defaults to red).

$warn: mat-palette($mat-red);
// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".

$theme: mat-light-theme(( color: ( primary: $primary, accent: $accent, warn: $warn, )));
// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
// $custom-typography: mat-typography-config($font-family: 'Roboto Condensed');
// @include angular-material-typography($custom-typography);

@include angular-material-theme($theme);
.cdk-global-overlay-wrapper,
.cdk-overlay-container {
    z-index: 9999 !important;
}

.mat-progress-bar-fill::after {
    background-color: #D05A7C;
}