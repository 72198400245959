/*== MAIN COLOR ==*/
$header-box: #F9E4EB
$body-box: #ffffff
$base-text: #423F3A
$quinque-color: #eee
$about-background-color: rgba($quinque-color,0.75)

$metro-lime: #A8C633
$metro-orange: #f09609
$metro-blue: #1ba1e2
$metro-red: #e51400

$font-orange: #EFB45B
$font-yellow: #FBDC69

$gold-1: #f8ec8d
$gold-2: #cfad54
$gold-3: #a26e22

=keyframes($name)
  @-webkit-keyframes #{$name}
    @content
  @-moz-keyframes #{$name}
    @content
  @-ms-keyframes #{$name}
    @content
  @keyframes #{$name}
    @content

/*== MAIN COLOR BUTTON==*/
$base-btn-primary: #707070

//$min-vw = minimum vw
//$max-vw = maximun vw
//$min-font-size = minimum font-size
//$max-font-size = maximun font-size
@mixin calc-font-size($min-vw, $max-vw, $min-font-size, $max-font-size)
  font-size: calc(#{$min-font-size}rem + ((100vw - #{$min-vw}rem) / (#{$max-vw} - #{$min-vw})) * (#{$max-font-size} - #{$min-font-size}))

@mixin calc-width($min-vw, $max-vw, $min-width, $max-width)
  width: calc(#{$min-width}rem + ((100vw - #{$min-vw}rem) / (#{$max-vw} - #{$min-vw})) * (#{$max-width} - #{$min-width})) !important

@mixin calc-min-width($min-vw, $max-vw, $min-min-width, $max-min-width)
  min-width: calc(#{$min-min-width}rem + ((100vw - #{$min-vw}rem) / (#{$max-vw} - #{$min-vw})) * (#{$max-min-width} - #{$min-min-width}))

@mixin calc-max-width($min-vw, $max-vw, $min-max-width, $max-max-width)
  max-width: calc(#{$min-max-width}rem + ((100vw - #{$min-vw}rem) / (#{$max-vw} - #{$min-vw})) * (#{$max-max-width} - #{$min-max-width}))

@mixin calc-height($min-vw, $max-vw, $min-height, $max-height)
  height: calc(#{$min-height}rem + ((100vw - #{$min-vw}rem) / (#{$max-vw} - #{$min-vw})) * (#{$max-height} - #{$min-height}))

@mixin calc-min-height($min-vw, $max-vw, $min-min-height, $max-min-height)
  min-height: calc(#{$min-min-height}rem + ((100vw - #{$min-vw}rem) / (#{$max-vw} - #{$min-vw})) * (#{$max-min-height} - #{$min-min-height}))

@mixin calc-max-height($min-vw, $max-vw, $min-max-height, $max-max-height)
  max-height: calc(#{$min-max-height}rem + ((100vw - #{$min-vw}rem) / (#{$max-vw} - #{$min-vw})) * (#{$max-max-height} - #{$min-max-height}))

@mixin calc-top($min-vw, $max-vw, $min-top, $max-top)
  top: calc(#{$min-top}rem + ((100vw - #{$min-vw}rem) / (#{$max-vw} - #{$min-vw})) * (#{$max-top} - #{$min-top}))

@mixin calc-bottom($min-vw, $max-vw, $min-bottom, $max-bottom)
  bottom: calc(#{$min-bottom}rem + ((100vw - #{$min-vw}rem) / (#{$max-vw} - #{$min-vw})) * (#{$max-bottom} - #{$min-bottom}))

@mixin calc-margin-bottom($min-vw, $max-vw, $min-margin-bottom, $max-margin-bottom)
  margin-bottom: calc(#{$min-margin-bottom}rem + ((100vw - #{$min-vw}rem) / (#{$max-vw} - #{$min-vw})) * (#{$max-margin-bottom} - #{$min-margin-bottom}))

.noneselect
    -webkit-touch-callout: none
    -webkit-user-select: none
    -khtml-user-select: none
    -moz-user-select: none
    -ms-user-select: none
    user-select: none
///////////////////////////////////////////////////////////
.bems-loader-wrapper
    position: fixed
    width: 100%
    height: 100%
    background-color: $about-background-color
    z-index: 9999

    .bems-loader
        margin: auto
        position: absolute
        top: 0
        left: 0
        bottom: 0
        right: 0
        width: 100px
        height: 100px
        &:before
            content: ''
            display: block
            padding-top: 100%

        .circular
            animation: rotate 2s linear infinite
            height: 100%
            transform-origin: center center
            width: 100%
            position: absolute
            top: 0
            bottom: 0
            left: 0
            right: 0
            margin: auto

        .path
            stroke-dasharray: 1, 200
            stroke-dashoffset: 0
            animation: dash 2s ease-in-out infinite, color 6s ease-in-out infinite
            stroke-linecap: round

+keyframes(rotate)
    100%
        transform: rotate(360deg)

+keyframes(dash)
    0%
        stroke-dasharray: 1, 200
        stroke-dashoffset: 0

    50%
        stroke-dasharray: 89, 200
        stroke-dashoffset: -35px

    100%
        stroke-dasharray: 89, 200
        stroke-dashoffset: -124px

+keyframes(color)
    100%,
    0%
        stroke: $metro-lime

    40%
        stroke: $metro-blue

    66%
        stroke: $metro-red
    80%,
    90%
        stroke: $metro-orange
        
.noneselect
  -webkit-touch-callout: none
  -webkit-user-select: none
  -khtml-user-select: none
  -moz-user-select: none
  -ms-user-select: none
  user-select: none

.body-page   
  padding: 4vh 4vw
  // flex: 1 0 auto
  min-height: 85vh

.display-block
  display: block !important

.display-flex
  display: flex

.display-none
  display: none

////////////////////////////////////////////////////////////
.btn
  background-color: $base-text
  border-color: transparent

.btn:focus
  box-shadow: none

.btn-primary
    background-color: $base-btn-primary !important
html
    margin: 0
    padding: 0
    width: 100%
    font-size: 16px
    overscroll-behavior: none

body
    margin: 0
    font-family: "Roboto Condensed", "Sarabun", "sans-serif" !important
    font-size: 1rem
    font-weight: 400
    line-height: 1.5
    color: #212529
    text-align: left

.card
    border: none
    width: -webkit-fill-available
    box-shadow: none
    border-radius: 15px
    background-color: rgba(0, 0, 0, .3)

.card-header
    background-color:  #000 !important
    // height: 4vh
    display: flex
    align-items: center
    justify-content: space-between
    font-size: initial
    font-weight: bold
    border-bottom: none !important

.card-body
    background-color: rgba(0, 0, 0, .3)
    color: #fff
    border-radius: 15px
    
.map-container 
    width: 100% !important
    height: 80vh !important
    margin: 0
    padding: 0
    overflow: hidden
    position: relative

.float-btn
  z-index: 99
  position: absolute
  bottom: 13.5vh
  right: 0.75rem
  justify-content: flex-end
  flex-direction: column
  align-items: flex-end
@media (min-width: 320px) and (max-width: 375px) //for smaller
  .float-btn
    bottom: 13vh
@media screen and (min-width: 768px) //for ipad
  .float-btn
    bottom: 15vh

.button-default
  border: none
  z-index: 99
  position: absolute
  align-items: center
  right: 1rem
  @include calc-top(20, 90, 4, 7.5) //t
  border-radius: 4rem
  line-height: 20px
  @include calc-font-size(20, 90, 0.69, 0.99) //f 10
  font-weight: bold
  height: 60px
  width: 60px
  padding: 0
  color: black
  background: linear-gradient(180deg, rgba(2,0,36,1) 0%, rgba(251,225,106,1) 0%, rgba(247,190,100,1) 50%, rgba(219,154,70,1) 100%)
  @media screen and (max-width: 962px)
      height: 50px
      width: 50px
      @include calc-top(20, 90, 4, 6) //t
  @media (min-width: 963px) and (max-width: 1024px)
      @include calc-top(20, 90, 4, 6) //t
  @media (min-width: 1025px) and (max-width: 1440px)
      @include calc-top(20, 90, 4, 6) //t
  @media (min-width: 1441px) and (max-width: 2559px)
      height: 80px
      width: 80px
      @include calc-top(20, 90, 3, 5.5) //t
  @media (min-width: 2560px) and (max-width: 4479px)
      height: 80px
      width: 80px
      @include calc-top(20, 90, 3, 5) //t
  @media screen and (min-width: 4480px)
      height: 90px
      width: 90px
      @include calc-top(20, 90, 1, 3.5) //t

.dropdown-menu
  background-color: $base-text
  @include calc-font-size(20, 90, 0.5, 1) //f 19
  @include calc-min-width(20, 90, 1, 4) //minw
  padding: 0.5vh 0.5vw 

.dropdown-item
  color: white
  padding: 0.75vh 0.5vw 

.dropdown-item:hover
  color: $base-text
  cursor: pointer
  background-color: $font-yellow

.dropdown-item:active
  background-color: $font-orange
